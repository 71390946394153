import React from 'react'
import shortid from 'shortid'
import { ExposeStat } from 'src/ubt.v2/exposeStat'
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
/**
 * 无结果大提示组件
 * @iconClassName: 搜索无结果推荐ico-noresult, 搜索无直飞推荐ico-nodirect
 * @label: text 很遗憾，未查询到指定机场航班/直飞航班/无直飞航班，为您推荐, 为您提供更多时间和航司选择，补充更多舱位航班
 * @cont: array 补充说明
 */
const NoResultTip = ({ ...props }) => {
    const { label, iconClassName, cont } = props
    sendBlockTrace({
            blockType: BlockTypeValue.Pop,
            interruptType: InterruptTypeValue.Block,
            mainType:  MainTypeValue.Search,
            subType: SubTypeValue.NoResult,
        });
    return (
      <ExposeStat ubtKey='S_Flt_N_List_noresult'>
        <div className='no-result'>
            <i className={iconClassName || "ico-noresult"}></i>
            <div className="title">
                {label || `很遗憾，未查询到指定机场航班，为您推荐`}
                {cont ?
                <div className="cont">
                    {cont.map((c,index)=> {
                        return <p key={shortid.generate()}>{index + 1}. {c}</p>
                    })}
                </div>
                : null
            }
            </div>

        </div>
      </ExposeStat>
    )
}

export default NoResultTip
