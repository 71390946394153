import React from 'react'
import NoResultTip from './noResultTip'
import GroupTitle from './groupTitle'

const FilterNoResultRecommend = ({ ...props }) => {
    let { reason } = props

    return (
        <div className="new-demand">
            <NoResultTip iconClassName="ico-noresult" label={reason.text} />
            <GroupTitle iconClassName={reason.iconClass || 'ico-subairport'} title={reason.title} tip={reason.hint}/>
        </div>
    )
}

export default FilterNoResultRecommend