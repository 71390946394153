import React from 'react';
import { connect } from 'react-redux';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import {
	currentSegmentSeqSelector,
	getMultiCheapestSelector,
	isBuildUpSelector,
	prevCondSelector,
	searchFlightsIsFinishedSelector,
	getRouterKeySelector,
	getIfScopeInternationalSelector,
} from '../flight/baseSelector';
import SubAirportRecommendContainer from './subAriportRecommendContainer';
import MultiCheapestContainer from './multiCheapestContainer';
import FilterNoResultRecommend from './filterNoResultRecommendContainer';
import NoResultTip from '../../../../components/list/result/recommend/noResultTip';
import { getDirectRecommendTab } from './directRecommendSelector';
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector';
import { isShowRecommendSelector } from './recommendSelector';
import { adjacentAirlineFormatSelector } from './adjacentAirlineSelector';
import { adjacentDateSelector } from './adjacentDateSelector';
import DemandFormContainer from './demandFormContainerAsync';

class RecommendContainer extends React.Component {
	render() {
		const { searchFlightsIsFinish, prevCond } = this.props;
		if (!searchFlightsIsFinish) {
			return null;
		}

		const {
			currentSegmentSeq,
			onPopupFlightDetail,
			multiCheapest,
			ubtOriginFlight,
			showRecommend: showThemeRecommend,
			showNearByRecommend,
			scopeInternational,
			searchNoResultRecommend,
		} = this.props;

		const notAnyRecommend = !showThemeRecommend && !showNearByRecommend; // 没有任何推荐

		let tipsSegment = prevCond.getIn(['flightSegments', 0]),
			dCityText = tipsSegment.get('departureAirportName')
				? `${tipsSegment.get('departureCityName')}(${tipsSegment.get(
						'departureAirportName'
				  )})(${tipsSegment.get('departureCityCode')})`
				: `${tipsSegment.get('departureCityName')}(${tipsSegment.get('departureCityCode')})`;
		let aCityText = tipsSegment.get('arrivalAirportName')
				? `${tipsSegment.get('arrivalCityName')}(${tipsSegment.get('arrivalAirportName')})(${tipsSegment.get(
						'arrivalCityCode'
				  )})`
				: `${tipsSegment.get('arrivalCityName')}(${tipsSegment.get('arrivalCityCode')})`,
			startDateText = tipsSegment.get('departureDate');

		let recommendContent = null;
		if (scopeInternational) {
			recommendContent = notAnyRecommend ? ( // 没有主题推荐或邻近推荐，才需要显示需求单
				<ErrorBoundary>
					<DemandFormContainer />
				</ErrorBoundary>
			) : null;
		} else {
			recommendContent = searchNoResultRecommend ? (
				<NoResultTip
					label={`您查询的 ${dCityText} 至 ${aCityText}（出发日期：${startDateText}） 的机票可能因无航班或航班座位已售完导致暂时无法查询到对应价格。
             建议您更换旅行日期或旅行城市重新查询`}
				/>
			) : null;
		}

		// 第二程及以上不会出现推荐，所以无结果时展示需求单
		//搜索无结果时，头部推荐邻近航线和这段时间更便宜不展示,展示为腰部推荐
		return (
			<ErrorBoundary>
				<div>
					{recommendContent}
					{scopeInternational && <SubAirportRecommendContainer ubtOriginFlight={ubtOriginFlight} />}
					{/* 国际航班才需要显示多程超低价 */}
					{scopeInternational && multiCheapest.size && currentSegmentSeq === 0 ? (
						<MultiCheapestContainer
							onBook={this.props.onBook}
							multiCheapest={multiCheapest}
							onPopupFlightDetail={onPopupFlightDetail}
							ubtOriginFlight={ubtOriginFlight}
						/>
					) : null}
					<FilterNoResultRecommend />
				</div>
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		searchFlightsIsFinish: searchFlightsIsFinishedSelector(state),
		prevCond: prevCondSelector(state),
		adjacentAirline: adjacentAirlineFormatSelector(state),
		adjacentDate: adjacentDateSelector(state),
		directRecommendList: getDirectRecommendTab(state),
		searchNoResultRecommend: searchNoResultRecommendSelector(state),
		currentSegmentSeq: currentSegmentSeqSelector(state),
		isBuildUp: isBuildUpSelector(state),
		multiCheapest: getMultiCheapestSelector(state),
		isShowRecommend: isShowRecommendSelector(state),
		routerKey: getRouterKeySelector(state),
		scopeInternational: getIfScopeInternationalSelector(state),
	};
};

const mapDispatchToProps = (_dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendContainer);
